// src/components/Subscription/FailurePage.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const FailurePage = () => {
  return (
    <Container>
      <Box textAlign="center" mt={5}>
        <Typography variant="h4" gutterBottom>
          Payment Failed!
        </Typography>
        <Typography variant="body1">
          Unfortunately, your payment could not be processed. Please try again.
        </Typography>
      </Box>
    </Container>
  );
};

export default FailurePage;
