// src/components/Layout/MainPage.js
import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import Watchlist from '../Watchlist/Watchlist';
import TradingViewWidget from '../Chart/TradingViewWidget';
import Chat from '../Chat/Chat';
import AIChatEntry from '../AIChat/AIChatEntry';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const MainPage = () => {
  const [selectedAsset, setSelectedAsset] = useState("NASDAQ:AAPL");

  return (
    <ErrorBoundary>
      <Box display="flex" flexDirection="column" minHeight="93vh" bgcolor="#151030">
        <Box style={{ maxWidth: '100%', padding: '0 16px', flex: '1 0 auto' }}>
          <Grid container spacing={1} style={{ marginTop: '0px', marginBottom: '8px' }}>
            <Grid item xs={12} md={2}>
              <Box bgcolor="#343232" height="500px" p={0} borderRadius={0} boxShadow={2}>
                <Box height="40px" bgcolor="#2a2727" display="flex" alignItems="center" pl={2} width="100%">
                  <Box component="span" style={{ color: '#bcb6b6', fontSize: '10px', letterSpacing: '0.2em', textAlign: 'left' }}>Watchlist</Box>
                </Box>
                <Box p={2}>
                  <Watchlist setSelectedAsset={setSelectedAsset} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box bgcolor="#343232" height="500px" p={0} borderRadius={2} boxShadow={2}>
                <TradingViewWidget asset={selectedAsset} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box bgcolor="#343232" height="500px" p={0} borderRadius={2} boxShadow={2} display="flex" flexDirection="column">
                <Box height="40px" bgcolor="#2a2727" display="flex" alignItems="center" pl={2} width="100%">
                  <Box component="span" style={{ color: '#bcb6b6', fontSize: '10px', letterSpacing: '0.2em', textAlign: 'left' }}>
                    <span style={{ cursor: 'pointer', marginRight: '8px' }}>{'←'}</span> Main Chat
                  </Box>
                </Box>
                <Box flex={1} position="relative" overflow="hidden">
                  <Chat />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={2}>
              <Box bgcolor="#2a2727" height="240px" p={2} borderRadius="2px 0 0 2px" boxShadow={2} display="flex" flexDirection="column">
                <Box component="span" style={{ color: '#bcb6b6', fontSize: '10px', letterSpacing: '0.2em', textAlign: 'left', marginBottom: '16px' }}>Chat 1</Box>
                <Box component="span" style={{ color: '#bcb6b6', fontSize: '10px', letterSpacing: '0.2em', textAlign: 'left', marginBottom: '16px' }}>Chat 2</Box>
                <Box component="span" style={{ color: '#bcb6b6', fontSize: '10px', letterSpacing: '0.2em', textAlign: 'left', marginBottom: '16px' }}>Chat 3</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Box bgcolor="#343232" height="240px" p={0} borderRadius="0 2px 2px 0" boxShadow={2} display="flex" flexDirection="column" justifyContent="space-between">
                <Box flex={1} position="relative">
                  <AIChatEntry />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default MainPage;
