// src/components/Auth/Signup.js
import React from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';

const Signup = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle signup logic
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Sign Up
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField label="Email" type="email" fullWidth margin="normal" required />
        <TextField label="Password" type="password" fullWidth margin="normal" required />
        <TextField label="Confirm Password" type="password" fullWidth margin="normal" required />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Sign Up
        </Button>
      </form>
    </Container>
  );
};

export default Signup;
