import React from 'react';
import { Container, Typography, Grid, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const backgroundImage = {
  backgroundImage: 'url(/wavy.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const FeatureCard = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  backgroundColor: '#fff',
  height: '470px',
}));

const FeatureCardAlt = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  backgroundColor: '#E6E9F5',
  border: '1px solid #dfdfdf',
  height: '470px',
}));

const TextGroup = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'center',
  width: '261px',
  fontFamily: 'var(--font-inter)',
  color: '#000',
}));

const CenterTextGroup = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'center',
  width: '261px',
  fontFamily: 'var(--font-inter)',
  color: '#000',
  margin: '0 128px',
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '450px',
  height: '300px',
  position: 'absolute',
  top: '85px',
  borderRadius: '8px', // Add rounded corners
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '170px',
  height: '48px',
  borderRadius: '8px',
  backgroundColor: '#3662e3',
  '& .MuiTypography-root': {
    fontSize: '20px',
    fontWeight: '500',
    color: '#fff',
    textTransform: 'capitalize',
  },
}));

const Question = styled(Typography)(({ theme }) => ({
  width: '429px',
  position: 'relative',
  fontSize: '20px',
  lineHeight: '120.94%',
  display: 'inline-block',
  fontFamily: 'var(--font-inter)',
  color: '#000',
  textAlign: 'left',
  fontWeight: '700',
  marginBottom: '9px',
}));

const Answer = styled(Typography)(({ theme }) => ({
  width: '511px',
  position: 'relative',
  fontSize: '16px',
  lineHeight: '120.94%',
  fontFamily: 'var(--font-inter)',
  color: '#000',
  whiteSpace: 'pre-wrap',
  textAlign: 'left',
  display: 'inline-block',
  marginBottom: '9px',
}));

const LandingPage = () => {
  return (
    <>
      <div style={backgroundImage}>
        <Container>
          <Grid container spacing={4} alignItems="center" justifyContent="flex-start" style={{ minHeight: '100vh', marginTop: '-300px' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" component="h1" gutterBottom>
                Welcome to Signal Sherpa
              </Typography>
              <Typography variant="h5" component="p" gutterBottom>
                Your AI Trading Companion for Charts & Analytics
              </Typography>
              <StyledButton variant="contained" component={Link} to="/Pricing">
                <Typography>Get Started</Typography>
              </StyledButton>
            </Grid>
          </Grid>
        </Container>
      </div>

      <FeatureCard>
        <Container>
          <Typography variant="h2" sx={{ fontSize: '50px', fontWeight: '900', mt: 10, textAlign: 'center' }}>
            10 X Trading
          </Typography>
          <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '500', mt: 4, mb: 3, textAlign: 'center', maxWidth: '770px', margin: '0 auto' }}>
            Signal Sherpa is your AI Trading Companion for Stocks, Options & Crypto. 10X your portfolio with AI interpreted Charts, Signals & Analytics.
          </Typography>
          <Box sx={{ position: 'relative', height: '0', mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '74px' }}>
              <TextGroup>
                <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: '900' }}>
                  AI Charts
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '18px', mt: 1 }}>
                  Trade signal drawings overlayed directly on the chart
                </Typography>
              </TextGroup>
              <CenterTextGroup>
                <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: '900' }}>
                  AI Analytics
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '18px', mt: 1 }}>
                  Asset analytics interpreted into actionable trade signals
                </Typography>
              </CenterTextGroup>
              <TextGroup>
                <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: '900' }}>
                  Trade Signals
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '18px', mt: 1 }}>
                  Find Trade Signals from any Asset, Chart or watchlist
                </Typography>
              </TextGroup>
            </Box>
          </Box>
        </Container>
      </FeatureCard>

      <FeatureCardAlt>
        <Container>
          <Grid container alignItems="center" height="100%" sx={{ position: 'relative' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ fontSize: '50px', fontWeight: '900', mt: 9, ml: 3 }}>
                Trading Strategy
              </Typography>
              <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '500', mt: 2, ml: 3, color: '#5d5d5d' }}>
                Fine tune your Trading Strategy with suggestions based on Market Sentiment, News & AI Analytics
              </Typography>
              <StyledButton variant="contained" sx={{ mt: 4, ml: 3 }}>
                <Typography>More Info</Typography>
              </StyledButton>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ImageBox component="img" src="/Arrows.png" sx={{ marginLeft: '205px' }} />
            </Grid>
          </Grid>
        </Container>
      </FeatureCardAlt>

      <FeatureCard>
        <Container>
          <Grid container alignItems="center" height="100%" sx={{ position: 'relative' }}>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ImageBox component="img" src="/Intelligent chart.png" sx={{ marginRight: '205px' }} />
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Typography variant="h2" sx={{ fontSize: '50px', fontWeight: '900', mt: 9, mr: 3 }}>
                Intelligent Watchlist
              </Typography>
              <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '500', mt: 2, mr: 3, color: '#5d5d5d' }}>
                AI Consistently monitors your watchlist searching analytics, news & market sentiment for trade signals
              </Typography>
              <StyledButton variant="contained" sx={{ mt: 4, mr: 3 }}>
                <Typography>More Info</Typography>
              </StyledButton>
            </Grid>
          </Grid>
        </Container>
      </FeatureCard>

      <FeatureCardAlt sx={{ height: '696px' }}>
        <Container>
          <Typography variant="h2" sx={{ fontSize: '50px', fontWeight: '900', mt: 9, textAlign: 'center' }}>
            Frequently Asked Questions
          </Typography>
          <Grid container spacing={4} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6}>
              <Question variant="h6">Does Signal Sherpa find me trades?</Question>
              <Answer variant="body1">Yes, Signal Sherpa can find & supply trading suggestions based on an search, monitored watchlist or through an AI question prompt.  Probability predictions are provided along with trade suggestions. Note: Signal Sherpa is still learning & can get things wrong. Use suggestions with caution.</Answer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Question variant="h6">What AI Model does Signal Sherpa Use?</Question>
              <Answer variant="body1">Signal Sherpa has developed a custom finance AI model built on the back of Open AI’s most advanced GPT-4 model. Our model is in beta currently going thru its initial training phase & will continue to learn & advance far into the future.</Answer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Question variant="h6">Does Signal Sherpa offer API?</Question>
              <Answer variant="body1">Signal Sherpa offers a enterprise model that provides API access to its suite of services. Build and use Signal Sherpa’s services in any custom environment including cross platform integration or enterprise solutions.</Answer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Question variant="h6">Does Sherpa offer private servers?</Question>
              <Answer variant="body1">Yes, Signal Sherpa offers a Pro Team & Enterprise Subscription models that provides teams or firms their own private servers. Make Team based or individual watch/trade lists & private custom chat rooms for teams or your entire firm.</Answer>
            </Grid>
          </Grid>
        </Container>
      </FeatureCardAlt>
    </>
  );
};

export default LandingPage;
