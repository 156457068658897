import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, Box, Button, TextField, Avatar, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';
import axios from 'axios';

const ProfileContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundImage: 'url(/blob.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const Profile = () => {
  const [userData, setUserData] = useState({
    name: '',
    username: '',
    email: '',
    profilePicture: '',
  });
  const [profilePictureFile, setProfilePictureFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchData();
  }, []);

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfilePictureFile(e.target.files[0]);
      setUserData({ ...userData, profilePicture: URL.createObjectURL(e.target.files[0]) });
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('name', userData.name);
      formData.append('username', userData.username);
      formData.append('email', userData.email);
      if (profilePictureFile) {
        formData.append('profilePicture', profilePictureFile);
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/profile`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      // Fetch updated profile data
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUserData(response.data);

    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  return (
    <ProfileContainer>
      <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
              <Avatar
                alt="Profile Image"
                src={userData.profilePicture}
                sx={{ width: 120, height: 120, margin: 'auto' }}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => document.getElementById('profilePictureInput').click()}
              >
                <EditIcon />
              </IconButton>
              <input
                id="profilePictureInput"
                type="file"
                hidden
                onChange={handleProfileImageChange}
              />
              <Typography variant="h6">My Profile</Typography>
              <Typography variant="body2">Last login: 07 Aug 2024, 15:45</Typography>
              <Typography variant="body2">Windows 10 - New York, US</Typography>
              <Box mt={2}>
                <TextField
                  label="Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={userData.name}
                  onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                />
                <TextField
                  label="Username"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={userData.username}
                  onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                />
                <TextField
                  label="Email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={userData.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                />
                <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
              <Typography variant="h6">My Subscription</Typography>
              <Typography variant="body2" gutterBottom>Current Plan: Basic</Typography>
              <Typography variant="body2" gutterBottom>Next Billing Date: 01 Sep 2024</Typography>
              <Box mt={2}>
                <Button variant="contained" color="secondary" onClick={() => {}}>
                  Upgrade Plan
                </Button>
              </Box>
            </Paper>

            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
              <Typography variant="h6">Billing Information</Typography>
              <Typography variant="body2">Card: **** **** **** 1234</Typography>
              <Typography variant="body2">Billing Address: 123 Main St, New York, NY</Typography>
              <Typography variant="body2">Phone: (555) 555-5555</Typography>
              <Box mt={2}>
                <Button variant="contained" color="primary" onClick={() => {}}>
                  Edit Billing Details
                </Button>
              </Box>
            </Paper>

            <Paper elevation={3} style={{ padding: '16px' }}>
              <Typography variant="h6">Affiliate Referral</Typography>
              <Typography variant="body2">Invite your friends and earn $10 credit for each referral!</Typography>
              <TextField
                label="Referral Link"
                fullWidth
                margin="normal"
                variant="outlined"
                value="https://example.com/referral-link"
                InputProps={{
                  endAdornment: (
                    <Button onClick={() => {}}>
                      Copy
                    </Button>
                  ),
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ProfileContainer>
  );
};

export default Profile;
