// src/components/Watchlist/Watchlist.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Watchlist = ({ setSelectedAsset }) => {
  const assets = [
    { symbol: 'AAPL', price: 201.35, change: -3.1 },
    { symbol: 'SPY', price: 521.43, change: 1.4 },
    { symbol: 'TSLA', price: 452.31, change: 2.9 },
  ];

  return (
    <Box color="#BCB6B6">
      <Button variant="outlined" color="primary" fullWidth style={{ marginBottom: '8px', color: '#BCB6B6' }}>
        + Add to watchlist
      </Button>
      <Box mt={2}>
        {assets.map((asset) => (
          <Box key={asset.symbol} display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body1" style={{ fontSize: '10px', letterSpacing: '0.2em', fontFamily: 'var(--font-inter)', color: '#bcb6b6' }}>
              {asset.symbol}
            </Typography>
            <Typography variant="body1" style={{ fontSize: '10px', letterSpacing: '0.2em', fontFamily: 'var(--font-inter)', color: asset.change > 0 ? '#00FFC2' : '#EE0E0E' }}>
              {asset.price.toFixed(2)} {asset.change > 0 ? '▲' : '▼'} {Math.abs(asset.change).toFixed(1)}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Watchlist;
