// src/components/Subscription/Subscription.js
import React, { useState, useRef } from 'react';
import { Container, Typography, Box, Tabs, Tab, Card, CardContent, Button, Modal, TextField, Grid, Avatar } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const BackgroundContainer = styled(Box)({
  minHeight: '100vh',
  backgroundImage: "url('/cubes.png')",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const CheckoutForm = ({ userData, tab, handleClose, setError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubscription = async (event) => {
    event.preventDefault();
    setError('');

    if (!stripe || !elements) {
      setError('Stripe or elements not loaded');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setError(error.message);
    } else {
      try {
        const formData = new FormData();
        formData.append('name', userData.name);
        formData.append('username', userData.username);
        formData.append('email', userData.email);
        formData.append('password', userData.password);
        formData.append('subscription', tab);
        formData.append('profilePicture', userData.profilePicture);

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { token: authToken } = response.data;
        localStorage.setItem('token', authToken);

        await axios.post(`${process.env.REACT_APP_API_URL}/subscription/subscribe`, {
          token,
          subscription: tab,
          userId: response.data.user._id,
        });

        handleClose();
        navigate('/payment-success');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        } else {
          setError('An error occurred while processing your subscription.');
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubscription}>
      <CardElement />
      <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} type="submit">
        Subscribe
      </Button>
    </form>
  );
};

const Subscription = () => {
  const [tab, setTab] = useState('monthly');
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({ name: '', username: '', email: '', password: '', profilePicture: null, profilePicturePreview: null });
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleProfilePictureClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setUserData({ ...userData, profilePicture: e.target.files[0], profilePicturePreview: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const subscriptionDetails = {
    monthly: {
      price: '$49.99/month',
      benefits: [
        'Access to basic features',
        'Monthly analytics reports',
        'Email support',
      ],
    },
    yearly: {
      price: '$411.6/year',
      benefits: [
        'Access to all features',
        'Priority support',
        'Yearly analytics reports',
        'Free t-shirt',
      ],
    },
    freeTrial: {
      price: 'Free for 1 month',
      benefits: [
        'Access to basic features',
        'Monthly analytics reports',
        'Email support',
      ],
    },
  };

  return (
    <BackgroundContainer>
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Subscription Options
        </Typography>
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Monthly" value="monthly" />
          <Tab label="Yearly" value="yearly" />
          <Tab label="Free Trial" value="freeTrial" />
        </Tabs>
        <Box display="flex" justifyContent="center" mt={3} mb={3}>
          <Card style={{ maxWidth: 500, width: '100%' }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {subscriptionDetails[tab].price}
              </Typography>
              <Typography variant="body1" component="div">
                {subscriptionDetails[tab].benefits.map((benefit, index) => (
                  <Box key={index} mb={1}>
                    - {benefit}
                  </Box>
                ))}
              </Typography>
              <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleOpen}>
                Subscribe Now
              </Button>
            </CardContent>
          </Card>
        </Box>
        <Box mt={4} display="flex" justifyContent="center" textAlign="center">
          <Box>
            <Typography variant="h5" gutterBottom>
              Why Subscribe?
            </Typography>
            <Box mb={2}>
              <Typography variant="body1">
                - Access exclusive features only available to subscribers.
              </Typography>
              <Typography variant="body1">
                - Receive priority support and personalized assistance.
              </Typography>
              <Typography variant="body1">
                - Stay ahead with the latest analytics and market trends.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Modal open={open} onClose={handleClose}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Sign Up
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Name" name="name" fullWidth onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Username" name="username" fullWidth onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email" name="email" fullWidth onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Password" name="password" type="password" fullWidth onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Button variant="contained" component="span" onClick={handleProfilePictureClick}>
                    Upload Profile Picture
                  </Button>
                  <input
                    type="file"
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  {userData.profilePicturePreview && (
                    <Avatar
                      src={userData.profilePicturePreview}
                      sx={{ width: 60, height: 60, ml: 2 }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Typography variant="h6" component="h2" gutterBottom>
                Payment Details
              </Typography>
              {tab === 'freeTrial' && (
                <Typography variant="body2" gutterBottom>
                  Free for 1 month. No charge till {new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString()}.
                </Typography>
              )}
              <Elements stripe={stripePromise}>
                <CheckoutForm userData={userData} tab={tab} handleClose={handleClose} setError={setError} />
              </Elements>
            </Box>
            {error && (
              <Typography color="error" variant="body2" mt={2}>
                {error}
              </Typography>
            )}
          </Box>
        </Modal>
      </Container>
    </BackgroundContainer>
  );
};

export default Subscription;
