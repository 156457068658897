// src/components/Subscription/SuccessPage.js
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const SuccessPage = () => {
  return (
    <Box textAlign="center" mt={5}>
      <Typography variant="h4" gutterBottom>Congratulations!</Typography>
      <Typography variant="body1" gutterBottom>Your Signal Sherpa AI Companion awaits</Typography>
      <Button variant="contained" color="primary" component={Link} to="/dashboard">Go to Main Page</Button>
    </Box>
  );
};

export default SuccessPage;
