// src/components/Chat/Chat.js
import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Avatar, InputBase, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';

const Chat = () => {
  const [messages, setMessages] = useState([
    { id: 1, user: 'User1', text: 'Hey, how ya doing?', avatar: 'path/to/avatar1.png' },
    { id: 2, user: 'User2', text: 'Good man how about you?', avatar: 'path/to/avatar2.png' },
    { id: 3, user: 'User1', text: 'Awesome, just found this awesome signal', avatar: 'path/to/avatar1.png' }
  ]);

  const [inputValue, setInputValue] = useState('');
  const chatBoxRef = useRef(null);

  const handleSend = () => {
    if (!inputValue.trim()) return;
    const newMessage = {
      id: messages.length + 1,
      user: 'CurrentUser',
      text: inputValue,
      avatar: 'path/to/currentUserAvatar.png'
    };
    setMessages([...messages, newMessage]);
    setInputValue('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box color="#BCB6B6" height="100%" display="flex" flexDirection="column" position="relative">
      <Box flex={1} className="chat-container" ref={chatBoxRef} style={{ paddingBottom: '40px' }}>
        {messages.map((message) => (
          <Box key={message.id} display="flex" alignItems="center" mb={2} style={{ paddingLeft: '12px' }}>
            <Avatar src={message.avatar} alt={message.user} style={{ width: '30px', height: '30px', marginRight: '9px' }} />
            <Typography variant="body2" style={{ fontSize: '10px', letterSpacing: '0.2em', fontFamily: 'var(--font-inter)', color: '#bcb6b6', textAlign: 'left' }}>
              {message.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box className="chat-footer" display="flex" alignItems="center" style={{ borderTop: 'none' }}>
        <IconButton style={{ color: '#BCB6B6', marginLeft: '8px' }}>
          <AttachFileIcon />
        </IconButton>
        <Box flex={1} display="flex" alignItems="center" mx={1}>
          <InputBase
            placeholder="Chat"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{ flexGrow: 1, backgroundColor: '#000', borderRadius: '5px', paddingLeft: '8px', color: '#FFF', height: '27px', marginLeft: '8px', marginRight: '12px' }}
            inputProps={{ 'aria-label': 'chat input' }}
          />
        </Box>
        <IconButton style={{ color: '#00A3FF', marginRight: '8px' }} onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Chat;
