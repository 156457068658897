// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import LandingPage from './components/Layout/LandingPage';
import MainPage from './components/Layout/MainPage';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Subscription from './components/Subscription/Subscription';
import Profile from './components/Profile/Profile';
import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Layout/Footer';
import SuccessPage from './components/Subscription/SuccessPage';
import FailurePage from './components/Subscription/FailurePage';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

import './App.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Navbar />
          <div style={{ flex: '1' }}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/pricing" element={<Subscription />} />
              <Route path="/login" element={<Login />} /> {/* Use updated Login component */}
              <Route path="/signup" element={<Signup />} />
              <Route path="/dashboard" element={
                <PrivateRoute>
                  <MainPage />
                </PrivateRoute>
              } />
              <Route path="/profile" element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              } />
              <Route path="/payment-success" element={<SuccessPage />} />
              <Route path="/payment-failure" element={<FailurePage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
