// src/components/AIChat/AIChatEntry.js
import React, { useState } from 'react';
import { Box, InputBase, IconButton, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const AIChatEntry = ({ onQuery }) => {
  const [query, setQuery] = useState('');

  const handleQuery = () => {
    if (onQuery) onQuery(query);
    setQuery('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleQuery();
    }
  };

  return (
    <Box display="flex" height="100%" flexDirection="column" justifyContent="space-between">
      <Box flex={1} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        position="absolute"
        bottom="30px"
      >
        <Box
          width="70%"
          borderRadius="5px"
          bgcolor="#000"
          height="30px"
          display="flex"
          alignItems="center"
          pl={2}
          pr={6}
        >
          <InputBase
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{ color: '#bcb6b6', fontSize: '10px', letterSpacing: '0.2em', fontFamily: 'var(--font-inter)', flexGrow: 1 }}
          />
        </Box>
        <IconButton
          style={{
            marginLeft: '16px',
            backgroundColor: '#00A3FF',
            height: '30px',
            width: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            color: '#FFF',
          }}
          onClick={handleQuery}
        >
          <SendIcon />
        </IconButton>
      </Box>
      <Box textAlign="center" mt={2} style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}>
        <Typography style={{ color: '#bcb6b6', fontSize: '10px', letterSpacing: '0.2em', fontFamily: 'var(--font-inter)' }}>
          Signal Sherpa AI models Still in training & may make mistakes
        </Typography>
      </Box>
    </Box>
  );
};

export default AIChatEntry;
