import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AuthContext } from '../../contexts/AuthContext'; // Import AuthContext
import '@fontsource/inter'; // Import Inter font

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isAuthenticated, logout } = useContext(AuthContext); // Access auth state and logout function
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.fontFamily = 'Inter, sans-serif';
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to landing page on logout
  };

  return (
    <AppBar position="static" style={{ background: '#17171A', height: '60px', width: '100%' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px' }}>
        <Typography
          variant="h6"
          style={{ color: '#FFFFFF', fontSize: '18px', fontFamily: 'Inter, sans-serif', textDecoration: 'none' }}
          component={Link}
          to="/"
        >
          Signal Sherpa
        </Typography>
        <Box style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {isAuthenticated ? (
            <>
              <Link
                to="/dashboard"
                style={{ color: '#BCB6B6', textDecoration: 'none', fontSize: '14px', fontFamily: 'Inter, sans-serif' }}
              >
                Dashboard
              </Link>
              <IconButton color="inherit" component={Link} to="/profile">
                <AccountCircleIcon style={{ color: '#BCB6B6' }} />
              </IconButton>
              <IconButton
                color="inherit"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <SettingsIcon style={{ color: '#BCB6B6' }} />
              </IconButton>
              <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleLogout}>
                  Logout <ExitToAppIcon style={{ marginLeft: '8px' }} />
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Link
                to="/login"
                style={{ color: '#BCB6B6', textDecoration: 'none', fontSize: '14px', fontFamily: 'Inter, sans-serif' }}
              >
                Login
              </Link>
              <Link
                to="/pricing"
                style={{ color: '#BCB6B6', textDecoration: 'none', fontSize: '14px', fontFamily: 'Inter, sans-serif' }}
              >
                Pricing
              </Link>
              <IconButton
                color="inherit"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <SettingsIcon style={{ color: '#BCB6B6' }} />
              </IconButton>
              <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose} component={Link} to="/pricing">
                  Sign Up
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
