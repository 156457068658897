// src/components/Layout/Footer.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" py={3} px={2} sx={{ backgroundColor: '#333', color: '#fff' }} textAlign="center" width="100%">
      <Typography variant="body1">
        © 2024 Signal Sherpa. All rights reserved.
      </Typography>
      <Box mt={1}>
        <Link href="#" sx={{ color: '#fff' }} variant="body2">
          Privacy Policy
        </Link>
        {' | '}
        <Link href="#" sx={{ color: '#fff' }} variant="body2">
          Terms of Service
        </Link>
        {' | '}
        <Link href="#" sx={{ color: '#fff' }} variant="body2">
          Contact Us
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
